import React, { useEffect } from 'react';

const TermsAndConditions = ({ isOpen, onClose }) => {
	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (e.target.classList.contains('modal-overlay')) {
				onClose();
			}
		};

		if (isOpen) {
			window.addEventListener('click', handleOutsideClick);
		}

		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	}, [isOpen, onClose]);

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50">
			<div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
			<div className="relative bg-white w-[400px] md:w-[500px] p-4 rounded-md shadow-lg h-[80%] max-h-[600px] overflow-y-auto">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-2xl font-semibold">Terms and Conditions</h2>
					<div className="cursor-pointer" onClick={onClose}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6 text-gray-500"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</div>
				</div>
				<div className="overflow-y-auto">
            <p className="mb-5">
              By registering for NextGen Show, you (the "Participant") agree to the following terms and conditions:
            </p>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Eligibility</h2>
              Participants must be residents of African countries.
              Participants must be of legal age as defined by the laws of their respective countries. For minors, a legal guardian's consent is required.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Registration</h2>
              All registration information provided must be accurate and complete.
              Incomplete or false information may result in disqualification.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Audition Process</h2>
              Participants may be required to attend auditions in their respective regions as specified by NextGen Show.
              Participants are responsible for their own travel and accommodation expenses for auditions.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Original Content</h2>
              Performances presented by participants must be original and not infringe upon any copyright, trademark, or intellectual property rights.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Performance Rights</h2>
              By registering, participants grant NextGen Show the right to record, broadcast, and use their performances for promotional purposes.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Conduct</h2>
              Participants are expected to conduct themselves in a respectful and professional manner throughout the competition.
              Inappropriate behavior, including but not limited to harassment, discrimination, or use of offensive language, may result in disqualification.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Judging and Decisions</h2>
              The decisions of the judges and organizers are final and binding.
              Contestants agree to abide by the rules and guidelines set by the judges and organizers.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Prizes</h2>
              Contestants acknowledge that prizes are subject to taxes and regulations as applicable in their respective countries.
              Prize distribution and methods may vary and will be communicated by NextGen Show.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Liability and Indemnity</h2>
              Contestants assume full responsibility for their participation in NextGen Show.
              Contestants release NextGen Show, its organizers, partners, and sponsors from any liability arising from participation.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Modification or Cancellation</h2>
              NextGen Shows reserves the right to modify, extend, or cancel any aspect of the competition if circumstances require.
            </div>
            <div className="mt-5 text-justify">
              <h2 className="text-[#017297] font-semibold mb-1">Data Privacy</h2>
              Personal data provided during registration will be used solely for the purposes of NextGen Show and will not be shared with third parties without consent.
              By registering for NextGen Show, participants acknowledge that they have read, understood, and agreed to these terms and conditions.
            </div>
        </div>
			</div>
		</div>
	);
};

export default TermsAndConditions;
