import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";
export default function PaymentStatus() {
  const navigate = useNavigate();
  const { search } = useLocation();

  ////console.log(search.split("=")[1]);
  ////console.log(search);
  return (
    <div className="w-full h-full inset-0 bg-white fixed">
      <div className="w-[95%] sm:w-[400px] py-4 px-2 sm:px-4 flex items-center inset-0 rounded-sm justify-center flex-col gap-4 sm:gap-6 h-fit bg-gray-50 absolute shadow-lg m-auto">
        {search.split("=")[1] === "success" ? (
          <IoCheckmarkCircleOutline className="text-4xl text-green-500" />
        ) : (
          <AiOutlineCloseCircle className="text-4xl text-red-500" />
        )}
        {search.split("=")[1] === "success" ? (
          <div className="w-full flex flex-col gap-2 sm:gap-4 items-center justify-center">
            <div className="">Payment Successful</div>
            <button
              onClick={() => {
                navigate("/events");
              }}
              className="px-4 py-2 text-white rounded-sm bg-green-500 text-center"
            >
              Back
            </button>
          </div>
        ) : (
          <div className="w-full flex flex-col gap-2 sm:gap-4 items-center justify-center">
            <div className="">Payment Failed</div>
            <button
              onClick={() => {
                navigate("/events");
              }}
              className="px-4 py-2 text-white rounded-sm bg-red-500 text-center"
            >
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
